import React from "react"
import { OgTags } from "../components/OpenGraph";
import IFrameWorks from "../components/IFrameWorks"


function samplePreview() {

  return (
    <>
      <OgTags
        ogTitle="Fullstack HQ | Fullstack-Team-as-a-Service for Your Business"
        ogDescription="Fullstack HQ is a one-of-a-kind platform that provides unlimited, high-quality design & development work for a flat rate. Learn more with your free trial today."
        ogUrl="https://fullstackhq.com"
      />
      <main className="sample-preview">
        <IFrameWorks />
      </main>
    </>
  )
}

export default samplePreview
