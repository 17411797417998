import React , {useState, useEffect} from "react"
import { Link } from "gatsby"

function iFrame() {
  const [view, setView] = useState('desktop')
  const [previewSite, setPreviewSite] = useState('https://psdwizard.com/')
  const [previousLink, setPreviousLink] = useState('')
  const [buttons, setButtons] = useState([
    {
      name: 'Desktop',
      active: true,
      class: 'desktop'
    }, {
      name: 'Tablet',
      active: false,
      class: 'tablet'
    }, {
      name: 'Tablet Portrait',
      active: false,
      class: 'tablet-portrait'
    }, {
      name: 'Mobile',
      active: false,
      class: 'mobile'
    }
  ])

  const handleViewClick = (view) => {
    let copyButtons = buttons
    copyButtons.map(btn => {
      return btn === view ? btn.active = true : btn.active = false
    })
    setView(view.class)
    setButtons(copyButtons)
  }

  const handleClick = () => {
    if (typeof window !== 'undefined') {
			if (window.Beacon) {
				window.Beacon('toggle')
			} else {
				alert('Chat helper connection timeout. Please refresh and try again.')
			}
		}
  }

  useEffect(() => {
    setPreviewSite(sessionStorage.getItem('previewSite'))
    setPreviousLink(sessionStorage.getItem('previousLink'))
  }, [])

  return (
    <div className="section-iframe">
      <div className="btn-group-wrapper">
        <div className="btn-actions">
          <div className="btn-group">
            <Link to="/" className="navbar-brand-link">
              <img
                src={require("../assets/svg/fullstackhq-logo.svg")}
                className="navbar-brand"
                alt= "Fullstack HQ"
              />
            </Link>
            {buttons.map((btn, i) => {
              return <button key={i} className={btn.active ?  'btn btn-blue' : 'btn btn-outline-blue'} onClick={() => handleViewClick(btn)}>{btn.name}</button>
            })}
          </div>
        </div>
        <div className="btn-other-actions">
          <div className="btn-group">
            <Link to={`/${previousLink}`} className="btn btn-outline-blue">Back</Link>
            <button className="btn btn-blue" onClick={handleClick}>Contact us</button>
          </div>
        </div>
      </div>
      <div className="iframe-wrapper">
        <iframe title="psdwizard" className={"psdwizard-preview " + view} src={previewSite}></iframe>
      </div>
    </div>
  )
}

export default iFrame
